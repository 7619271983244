import { CreateUUIDWithoutDash } from "../utils/helper";
import defaultUsers from "./defaultUsers";
import sign from "jwt-encode";

class User {
  static allUsers = [];

  _tokenSecret =
    "15df181ebda9897193cd2dde03ede3feb6502112006e0998c6a3f4807f6884b4d51070fff207a1e9801d9252a72ad81db66e3b65527c5f8e570441c5bab91664";

  constructor(data) {
    const {
      id,
      name,
      topMemberId,
      memberId,
      loginId,
      isOperator,
      companyDb,
      token,
      isDefault,
    } = data;
    this.id = id || CreateUUIDWithoutDash();
    this.name = name || this.generateName();
    this.topMemberId = topMemberId;
    this.loginId = loginId;
    this.isOperator = isOperator;
    this.memberId = memberId;
    this.companyDb = companyDb;

    this.isDefault = isDefault;
    this.isActive = false;
    this.token = token || this.generateJwtToken();
  }

  select() {
    User.allUsers.forEach((user) => user.unselect());
    this.isActive = true;
    User.allUsers.sort((a, b) => {
      // If a is active and b is not, move a before b
      if (a.isActive && !b.isActive) {
        return -1;
      }
      // If b is active and a is not, move b before a
      if (!a.isActive && b.isActive) {
        return 1;
      }
      // Otherwise, maintain the original order
      return 0;
    });
    User.syncLocalDb();
    return User.allUsers;
  }

  unselect() {
    this.isActive = false;
  }

  delete() {
    const allUsers = [...User.allUsers];
    const newUsers = allUsers.filter((u) => u.id !== this.id);
    User.allUsers = newUsers;
    User.syncLocalDb();
    return newUsers;
  }

  generateJwtToken() {
    const token = sign(
      {
        topMemberId: this.topMemberId,
        memberId: this.memberId,
        loginId: this.loginId,
        isOperator: this.isOperator,
        companyDb: this.companyDb,
      },
      this._tokenSecret
    );
    return token;
  }

  generateName() {
    return `${this.companyDb}-${this.isOperator ? "OPERATOR" : "MEMBER"}-${
      this.memberId
    }`;
  }

  static sync() {
    if (this.allUsers.length > 0) return this.allUsers;
    const users = localStorage.getItem("users");
    if (!users) {
      this.addDefaultUsers();
    }
    this.loadUsers();
    return this.allUsers;
  }

  static addDefaultUsers() {
    const allDefaultUsers = defaultUsers;
    localStorage.setItem("users", JSON.stringify(allDefaultUsers));
  }

  static loadUsers() {
    const allUsers = JSON.parse(localStorage.getItem("users"));
    for (const user of allUsers) {
      const newUser = new User(user);
      this.allUsers.push(newUser);
    }
    this.allUsers[0].isActive = true;
  }

  static saveNewUser(user) {
    this.allUsers = [...this.allUsers, user];
    localStorage.setItem("users", JSON.stringify(this.allUsers));
    return this.allUsers;
  }

  static syncLocalDb() {
    localStorage.setItem("users", JSON.stringify(this.allUsers));
  }
}

export default User;
