import React from "react";
import StyledContainer from "./style";
import UserChip from "../userChip";
import CreateUser from "../createUser";

const UserSelector = ({
  allUsers,
  closeDrawer,
  selectUser,
  createUser,
  startUser,
  deleteUser,
  editUser,
  selectEnv,
  environments,
  selectedEnv,
}) => {
  const selectedEnvId = selectedEnv.id;

  return (
    <StyledContainer>
      <div className="environment-selector">
        <div className="title">Environment</div>
        <div className="selector-list">
          {environments.map((env) => (
            <button
              className={`env-button ${env.id === selectedEnvId && "selected"}`}
              key={env.id}
              onClick={() => selectEnv(env)}
            >
              {env.name}
            </button>
          ))}
        </div>
      </div>
      <div className="users-list">
        {allUsers &&
          allUsers.map((user) => (
            <UserChip
              key={user.id}
              user={user}
              selectUser={selectUser}
              deleteUser={deleteUser}
              editUser={editUser}
            />
          ))}
      </div>

      <CreateUser
        closeDrawer={closeDrawer}
        createUser={createUser}
        startUser={startUser}
      />
    </StyledContainer>
  );
};

export default UserSelector;
