import React from "react";
import StyledChip from "./style";

const UserChip = ({ user, selectUser, deleteUser, editUser }) => {
  const {
    id,
    name,
    topMemberId,
    memberId,
    isOperator,
    companyDb,
    isActive,
    isDefault,
  } = user;
  return (
    <StyledChip onClick={() => selectUser(user)} isActive={isActive}>
      {!isDefault && (
        <div className="buttons-wrapper">
          <button
            onClick={(e) => {
              e.stopPropagation();
              editUser(user);
            }}
            className="action-button edit"
          >
            Edit
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              deleteUser(user);
            }}
            className="action-button delete"
          >
            Delete
          </button>
        </div>
      )}

      <span className="name">{name}</span>
      <div className="id-container">
        <span className="top-member id">{`TopMemberId: ${topMemberId}`}</span>
        <span className="member id">{`MemberId: ${memberId}`}</span>
      </div>
      <span className="isOperator">{isOperator ? "OPERATOR" : "MEMBER"}</span>
      <span className="db">{`DB: ${companyDb}`}</span>
    </StyledChip>
  );
};

export default UserChip;
