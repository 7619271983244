// import logo from "./logo.svg";
import { useEffect, useRef, useState } from "react";
import "./App.css";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import UserSelector from "./components/userSelector";
import User from "./controllers/user";


// const iframeSrc = "http://localhost:3000";
// const iframeSrc = "https://dev.proflowtree.com";
// const iframeSrc = "https://www.proflowtree.com";


const iframeSrc = "https://new.proflowtree.com";
const environments = [
  {
    id: 3,
    name: "PROD_NEW TYPE (CACHE)",
    url: "https://new.proflowtree.com",
  },
  {
    id: 4,
    name: "PROD_OLD_TYPE (NON-CACHE)",
    url: "https://www.proflowtree.com",
  },

  {
    id: 2,
    name: "DEV",
    url: "https://dev.proflowtree.com",
  },
  {
    id: 1,
    name: "LOCAL",
    url: "http://localhost:3000",
  },
];

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [allUsers, setAllUsers] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const selectedUserRef = useRef(selectedUser);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [env, setEnv] = useState(environments[0]);

  useEffect(() => {
    if (!allUsers) {
      const users = User.sync();
      console.log("users", users);
      setAllUsers(users);
      setSelectedUser(users[0]);
    }
  }, [allUsers]);

  useEffect(() => {
    selectedUserRef.current = selectedUser;
  }, [selectedUser]);

  useEffect(() => {
    setIsLoading(true);
    const iframe = document.getElementById("mlm-tree-frame");

    const { url: iframeSrc } = env;

    const handleMessage = () => {
      const accessToken = selectedUserRef.current?.token;

      const dataToSend = {
        source: "panacea",
        type: "accessToken",
        payload: { accessToken },
      };
      iframe.contentWindow.postMessage(dataToSend, iframeSrc);
      setIsLoading(false);
    };

    iframe.addEventListener("load", handleMessage);

    iframe.src = iframeSrc;
    return () => {
      iframe.removeEventListener("load", handleMessage);
    };
  }, [selectedUser, env]);

  const toggleDrawer = () => {
    setIsOpen((prev) => !prev);
  };

  const handleSelectUser = (user) => {
    if (!user) return;
    const newUsers = user.select();
    setAllUsers([...newUsers]);
    setIsOpen(false);
    setSelectedUser(user);
  };

  const handleCreateUser = (user) => {
    if (!user) return;
    const newUser = new User(user);
    const allUsers = User.saveNewUser(newUser);
    setAllUsers([...allUsers]);
    return newUser;
  };

  const handleStartUser = (user) => {
    if (!user) return;
    const newUser = handleCreateUser(user);
    handleSelectUser(newUser);
  };

  const handleDeleteUser = (user) => {
    if (!user) return;
    const allUsers = user.delete(user);
    setAllUsers([...allUsers]);
  };

  const handleEditUser = (user) => {
    if (!user) return;
  };

  return (
    <div className="App">
      <div className="dropdown-bar" onClick={toggleDrawer}>
        {selectedUser !== null && (
          <>
            <p>[ Name: {selectedUser.name} ]</p>
            <p>[ TopMemberId: {selectedUser.topMemberId} ]</p>
            <p>[ MemberId: {selectedUser.memberId} ]</p>
            <p>[ LoginId: {selectedUser.loginId} ]</p>
            <p>[ {selectedUser.isOperator ? "OPERATOR" : "MEMBER"} ]</p>
            <p>[ CompanyDB: {selectedUser.companyDb} ]</p>
          </>
        )}
      </div>
      <div className="iframe-container">
        {isLoading && (
          // <img className="loading-gif" src="/402107790_STATIC_NOISE_400.gif" alt="static-gif" />
          <img className="loading-gif" src="/71B.gif" alt="static-gif" />
        )}
        <iframe
          hidden={isLoading}
          title="proflow-dev-frame"
          // src={iframeSrc}
          id="mlm-tree-frame"
          className="tree-frame"
          allowFullScreen
        ></iframe>
      </div>

      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="top"
        // enableOverlay={false}
        overlayOpacity={0.1}
        size="40vh"
        duration={200}
        className="bla bla bla"
      >
        <UserSelector
          allUsers={allUsers}
          closeDrawer={toggleDrawer}
          selectUser={handleSelectUser}
          createUser={handleCreateUser}
          startUser={handleStartUser}
          deleteUser={handleDeleteUser}
          editUser={handleEditUser}
          selectEnv={(env) => setEnv(env)}
          environments={environments}
          selectedEnv={env}
        />
      </Drawer>
    </div>
  );
}

export default App;
