import Styled from "styled-components";

const StyledContainer = Styled.div`
    width: 100%;
    height: 100%;

    padding: 20px;
    display: flex;
    overflow: hidden;
    gap: 20px;

    .inputs-wrapper {
        display: grid;
        grid-template-rows: repeat(3, auto);
        gap: 10px;
        padding: 10px 20px;
    }

    .users-list {
        width: 400px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        /* border: 1px solid grey; */
        padding: 5px;

        align-items: center;
        overflow: hidden auto;
    }

    .row {
    display: flex;
    align-items: center;
    gap: 40px;
    }

        label {
    /* margin-right: 10px; */
    min-width: 120px;
    text-align: left;

   
    }

    .required {
        &:after {
        content: '*';
        color: red;
         }
    }

    input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    flex: 1;
    max-width: 400px;
        }  
        
    #toggle {
        flex: 0;
    }

    .environment-selector {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .selector-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    .env-button {
        width: 100%;
        height: 50px;

        &.selected {
            background: blue;
            color: #fff;
        }
    }
`;

export default StyledContainer;
