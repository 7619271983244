import Styled from "styled-components";

const StyledContainer = Styled.form`
    width: 100%;
    height: 100%;


    display: flex;
    flex-direction: column;
    gap: 20px;

    .inputs-wrapper {
        width: 100%;
        height: 100%;
        border: 1px solid grey;
    }

    .buttons-wrapper {
        width: 100%;
        height: 60px;
        /* border: 1px solid green; */

        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Three equal columns */
        gap: 10px; /* Gap between buttons */
    }

    .button {
        width: 100%;
        cursor: pointer;
        color: #fff;
        border: none;
        /* outline: none; */

        &:hover {
            opacity: 0.6;
        }
    }

    .save {
        background: blue;
    }

    .start {
        background: green;
    }

    .close {
        background: red;
    }
`;

export default StyledContainer;
