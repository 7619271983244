import Styled from "styled-components";

const StyledChip = Styled.button`
    width: 100%;
    height: fit-content;
    position: relative;
    padding: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: ${(props) =>
      props.isActive ? "1px solid #C6E5F1" : "1px solid rgba(0,0,0,.1)"} ;
      background: ${(props) => (props.isActive ? "#EDF8FC" : "none")} ;
    cursor: pointer;
    box-shadow: ${(props) => props.isActive && "0 1px 4px 0 rgba(0,0,0,.1)"};

    .id-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .buttons-wrapper {
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .action-button {
      padding: 3px;
      cursor: pointer;
    }

    .edit {
      color: blue;
    }

    .delete {
      color: red;
    }
`;

export default StyledChip;
