import React, { useState } from "react";
import StyledContainer from "./style";

const emptyUser = {
  name: "",
  topMemberId: "",
  memberId: "",
  loginId: "",
  isOperator: true,
  companyDb: "",
};

const CreateUser = ({ closeDrawer, createUser, startUser }) => {
  const [user, setUser] = useState(emptyUser);

  const handleUpdate = (key, e) => {
    const { value } = e.target;
    setUser((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleToggle = (e) => {
    const { checked } = e.target;
    setUser((prev) => ({
      ...prev,
      isOperator: checked,
    }));
  };

  const handleCreateUser = () => {
    createUser(user);
    setUser(emptyUser);
  };

  const handleStartUser = () => {
    startUser(user);
    setUser(emptyUser);
  };

  return (
    <StyledContainer onSubmit={handleStartUser}>
      <div className="inputs-wrapper">
        <div className="row">
          <label htmlFor="input1">Name:</label>
          <input
            type="text"
            id="input1"
            value={user.name}
            onChange={(e) => handleUpdate("name", e)}
          />
        </div>

        <div className="row">
          <label className="required" htmlFor="input2">
            TopMemberId:
          </label>
          <input
            type="text"
            id="input2"
            value={user.topMemberId}
            onChange={(e) => handleUpdate("topMemberId", e)}
            required
          />
          <label className="required" htmlFor="input3">
            MemberId:
          </label>
          <input
            required
            type="text"
            id="input3"
            value={user.memberId}
            onChange={(e) => handleUpdate("memberId", e)}
          />
          <label className="required" htmlFor="input4">
            loginId:
          </label>
          <input
            type="text"
            id="input4"
            value={user.loginId}
            onChange={(e) => handleUpdate("loginId", e)}
            required
          />
        </div>

        <div className="row">
          <label className="required" htmlFor="toggle">
            isOperator:
          </label>
          <input
            type="checkbox"
            id="toggle"
            checked={user.isOperator}
            onChange={(e) => handleToggle(e)}
            required
          />
          <label className="required" htmlFor="input5">
            CompanyDb:
          </label>
          <input
            type="text"
            id="input5"
            value={user.companyDb}
            onChange={(e) => handleUpdate("companyDb", e)}
            required
          />
        </div>
      </div>

      <div className="buttons-wrapper">
        <button
          type="submit"
          onClick={handleStartUser}
          className="start button"
        >
          SAVE AND START
        </button>
        {/* <button
          type="submit"
          onClick={handleStartUser}
          className="start button"
        >
          START
        </button> */}
        <button type="button" className="close button" onClick={closeDrawer}>
          CLOSE
        </button>
      </div>
    </StyledContainer>
  );
};

export default CreateUser;
